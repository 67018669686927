import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { SchoolsService, School } from '../../_services/schools.service';
import { PublishingService } from '../../_services/publishing.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { User } from '../../_models/user';
import { Session } from '../../_models/result';
import { QuestionsService } from '_services/questions.service';


@Component({
  selector: 'app-superadmin',
  templateUrl: './superadmin.component.html',
  styleUrls: ['./superadmin.component.css']
})
export class SuperadminComponent implements OnInit {
  schoolId: string;
  firebaseCommand: string;
  demo: boolean;
  noSchoolUsers: Observable<User[]>;
  activeUsers: Observable<User[]>;


  publishedPrefsPath: string;
  publishedPrefsForm: UntypedFormGroup;
  status: string;

  state: string;

  calcRulesPath: string;
  calcRulesForm: UntypedFormGroup;

  schoolPath: string;
  liveDatesForm: UntypedFormGroup;

  eclUserSessions: Observable<Session[]>;


  buttonAll = true;

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    public sa: SchoolsService,
    private qs: QuestionsService,
    private ps: PublishingService,
    private afs: AngularFirestore,
    private fb: UntypedFormBuilder
  ) {


    this.titleService.setTitle('Super Admin' + ' | NPC Admin');

    const noSchoolUsersCol = this.afs.collection<User>('admin_users', ref => ref.where('schools', '==', null).where('admin', '==', false));
    this.noSchoolUsers = noSchoolUsersCol.valueChanges();

  }

  ngOnInit() {
    this.schoolId = this.route.snapshot.parent?.paramMap.get('schoolId') ?? '';
    const activeUsersCol = this.afs.collection<User>('admin_users',
      ref => ref.where('schools', 'array-contains', this.schoolId).where('admin', '==', false));
    this.activeUsers = activeUsersCol.valueChanges();


    this.publishedPrefsPath = 'preferences/' + this.schoolId;
    this.publishedPrefsForm = this.fb.group({
      status: ['']
    });

    this.calcRulesPath = 'calcConfig/' + this.schoolId + '/calcRules/draft';
    this.calcRulesForm = this.fb.group({
      schoolProperties: new UntypedFormGroup({
        institutionalMethod: new UntypedFormControl(),
        orgId: new UntypedFormControl(),
        cssId: new UntypedFormControl()
      })
    });

    this.schoolPath = 'schools/' + this.schoolId;
    this.liveDatesForm = this.fb.group({
      live: [''],
      disabledDate: ['']
    });


  }

  changeHandler(e) {
    this.state = e;
  }

  processSavedChange(change) {
    if (change.schoolProperties) {
      this.sa.updateSchool(this.schoolId, change);
    }
  }

  setStatus() {
    const status = this.publishedPrefsForm.controls.status.value;
    if (status === '') {
      // Status is LIVE
      this.afs.doc('schools/' + this.schoolId).set({ disabledDate: null, status: null, live: new Date(Date.now()) }, { merge: true });
      this.afs.doc('preferences/' + this.schoolId).set({ disableSchool: false }, { merge: true });
      this.afs.doc('preferences_draft/' + this.schoolId).set({ disableSchool: false }, { merge: true });
    } else {
      this.afs.doc('schools/' + this.schoolId).set({ disabledDate: new Date(Date.now()), status: status }, { merge: true });
      this.afs.doc('preferences/' + this.schoolId).set({ disableSchool: true }, { merge: true });
      this.afs.doc('preferences_draft/' + this.schoolId).set({ disableSchool: true }, { merge: true });
    }
    // This gets set by the form
    // this.afs.doc('preferences/' + this.schoolId).set({ status: status }, { merge: true });
    // IF THIS IS SET THEN WE CAN'T SEE THE SITE IN DRAFT MODE - LEAVE AS NULL
    this.afs.doc('preferences_draft/' + this.schoolId).set({ status: null }, { merge: true });
  }

  markQuestionsRequired() {
    const institutionalMethodValue = this.calcRulesForm.get('schoolProperties.institutionalMethod').value;
    this.qs.flagDraftQuestions(this.schoolId, institutionalMethodValue);
  }

  utility() {

    alert('No active utility');
    // const from = 'amtest';
    // const to = 'default';
    // this.ps.utilityCopyDocument(
    //   'calcConfig/' + from + '/packages/AdjustedParentContribution',
    //   'calcConfig/' + to + '/packages/AdjustedParentContribution');
    // this.ps.utilityCopyDocument(
    //   'calcConfig/' + from + '/packages/AdjustedStudentContribution',
    //   'calcConfig/' + to + '/packages/AdjustedStudentContribution');
    // this.ps.utilityCopyDocument(
    //   'calcConfig/' + from + '/packages/PellGrant',
    //   'calcConfig/' + to + '/packages/PellGrant');

  }

  getEclUserRecords(eclUserId: string) {
    this.eclUserSessions = null;
    if (eclUserId.toUpperCase() !== 'GUEST') {
      const sessionCol = this.afs.collection<Session>('sessions',
        ref => ref.where('cbUserId', '==', eclUserId.toUpperCase())
      );
      this.eclUserSessions = sessionCol.valueChanges({ idField: 'id' });
    }
  }

  deleteUserSessions(sessions: Session[]) {
    const confirmation = prompt('Type yes to confirm deletion of ' + sessions.length + ' records');

    if (confirmation.toLowerCase() === 'yes') {
      sessions.forEach(session => {
        this.afs.doc(session.id).delete();
      });
    }
  }
  deactivate(user: User) {
    const index = user.schools.indexOf(this.schoolId);
    if (index !== -1) {
      user.schools.splice(index, 1);
      user.schools = user.schools.length === 0 ? null : user.schools;
    } else {
      console.log('Can not find', this.schoolId, 'when attempting to deactivate user.schools = ', user.schools);
    }
    this.afs.doc('admin_users/' + user.uid).set(user);
  }

  activate(user: User) {
    if (user.schools === null) {
      user.schools = [];
    }
    user.schools.push(this.schoolId);
    this.afs.doc('admin_users/' + user.uid).set(user);
  }

  deleteUser(user: User) {
    const confirmation = prompt('Type yes to confirm deletion of ' + user.email);

    if (confirmation.toLowerCase() === 'yes') {
      this.afs.doc('admin_users/' + user.uid).delete();
    }
  }


  newSchool(code: string, name: string, demoSchool: boolean) {
    const sanitizedCode = code.toLowerCase();
    // TODO: Add Initial Questions
    const school: School = {
      name: name,
      demo: demoSchool,
      status: 'COMING',
      disabledDate: new Date(Date.now()),
      schoolProperties: {
        draftAidYear: 2526,
        aidYear: 2526,
        cssId: null,
        institutionalMethod: false,
        orgId: null
      }
    };
    this.sa.newSchool(sanitizedCode, school)
      .then(next => {
        this.ps.utilityCopyDocument(
          'preferences_draft/' + this.schoolId,
          'preferences_draft/' + sanitizedCode);
        this.ps.utilityCopyDocument(
          'calcConfig/' + this.schoolId + '/calcRules/draft',
          'calcConfig/' + sanitizedCode + '/calcRules/draft');
        this.ps.utilityCopyDocument(
          'calcConfig/' + this.schoolId + '/calcRules/published',
          'calcConfig/' + sanitizedCode + '/calcRules/published');
        // Legacy, not needed anymore
        // this.ps.utilityCopyCollection(this.schoolId, sanitizedCode, 'expenses', 'type');
        // this.ps.utilityCopyDocument(
        //   'calcConfig/' + this.schoolId + '/packages/AdjustedParentContribution',
        //   'calcConfig/' + sanitizedCode + '/packages/AdjustedParentContribution');
        // this.ps.utilityCopyDocument(
        //   'calcConfig/' + this.schoolId + '/packages/AdjustedStudentContribution',
        //   'calcConfig/' + sanitizedCode + '/packages/AdjustedStudentContribution');
        // this.ps.utilityCopyDocument(
        //   'calcConfig/' + this.schoolId + '/packages/PellGrant',
        //   'calcConfig/' + sanitizedCode + '/packages/PellGrant');
      })
      .catch(error => {
        console.log('Error adding new school:', error);
      });
  }
}
